<template lang="pug">
div.container
  div.wrapper
    div.modal
      div.content(v-bind:class="{small_screen:isSmallScreen}")
        div.title {{title}}
        div.description If you do not know their birthdate, we will ask for you.
        div.contact_info
          div.label First name
          div.input_container
            input(v-model="firstName" type="text" placeholder="first name" v-bind:class="{highlight: highlightField=='first'}")
          div.label Last name
          div.input_container
            input(v-model="lastName" type="text" placeholder="last name" v-bind:class="{highlight: highlightField=='last'}")
          div.label Email
          div.input_container
            input(v-model="email" type="text" placeholder="email" v-bind:class="{highlight: highlightField=='email'}")
        div.birthdate_info
          div.label Birthdate
          DatePicker(v-model='birthdate' color="indigo" is-dark)
          div.tip Year does not matter.
        div.clear
        div.buttons
          button.cta(v-on:click="updateContact(true)") {{submitTitle }}
          button.cta_secondary.remove(v-if="editExisting" v-on:click="updateContact(false)") Remove
          button.cta_secondary(v-on:click="close") {{ closeTitle }}
          div.clear
  div.overlay

</template>

<script>
/* global gtag */

import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import { UPDATE_BIRTHDAY_CONTACT_MUTATION } from '../graphql/mutations'
import { isEmail } from '../lib/utils'
import config from '../appConfig'


export default {
  name: 'EditBirthdayContact',
  props: {
    contact: {
      type: Object,
      default: null,
      validator: function(value) {
        if (value == null) return true
        return value["email"] != undefined && isEmail(value["email"])
      }
    },
  },
  components: {
    DatePicker,
  },
  computed: {
    title () {
      return this.editExisting ? "Update birthday reminder" : "Add birthday reminder"
    },
    submitTitle () {
      if (this.loading) return this.editExisting ? "Updating..." : "Adding..."
      return this.editExisting ? "Update" : "Add"
    },
    closeTitle () {
      return  this.editExisting ? "Close" : "Cancel"
    },
    loadingSrc () {
      return process.env.BASE_URL + "loading_dark.gif"
    },
    editExisting () {
      return this.contact.id != undefined
    },
    isSignedIn () {
      return this.$store.getters.signedIn
    },
    isValidEmail () {
      return isEmail(this.contact.email)
    },
  },
  data () {
    return {
      firstName: this.contact?.firstName,
      lastName: this.contact?.lastName,
      email: this.contact?.email,
      birthdate: this.contact?.birthdate,
      highlightField: null,
      loading: false,
      isSmallScreen: config.isSmallScreen(),
    }
  },

  methods: {
    updateContact: function(active) {
      if (!isEmail(this.email)) {
        this.$toast.error('Enter a valid email',{
          timeout: 4000,
          onClose: () => this.highlightField = null
        })
        this.highlightField = "email"
        return
      }

      if (this.firstName == null || this.firstName == "") {
        this.$toast.error('Enter a first name',{
          timeout: 4000,
          onClose: () => this.highlightField = null
        })
        this.highlightField = "first"
        return
      }

      if (this.lastName == null || this.lastName == "") {
        this.$toast.error('Enter a last name',{
          timeout: 4000,
          onClose: () => this.highlightField = null
        })
        this.highlightField = "last"
        return
      }

      this.loading = true
      this.$apollo.mutate({
        mutation: UPDATE_BIRTHDAY_CONTACT_MUTATION,
        variables: {
          input: {
            id: this.contact?.id,
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            birthdate: this.birthdate,
            reminderActive: active,
          }
        }
      }).then((data) => {
        // console.log(data)
        this.loading = false
        const contact = data.data.updateContact.contact
        const errors = data.data.updateContact.errors
        this.errorsToast(errors)
        if (!errors) {
          this.updated(contact)
          this.close()
        }
      }).catch((error) => {
        this.loading = false
        console.error(error)
        this.errorToast(error)
      })
    },
    close: function() {
      this.$emit('closed')
    },
    updated: function(contact) {
      this.$emit('updated', contact)
    },
    onResize() {
      this.isSmallScreen = config.isSmallScreen()
    },
  },
  beforeDestroy() {
    // Unregister the event listener before destroying this Vue instance
    window.removeEventListener('resize', this.onResize)
  },
  mounted: function () {
    // window.vue = this
    gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID, {'page_path': '/edit_birthday_contact'})
    window.addEventListener('resize', this.onResize)
  }
}
</script>

<style scoped>

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  overflow-y: auto;
  overflow-x: visible;
}

.title {
  text-align: left;
  font-family: gibsonsemibold;
  font-size: 32px;
  margin-bottom:4px;
}

.description {
  text-align: left;
  margin-bottom:32px;
}

.modal {
  position: absolute;
  z-index: 150;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 90%;
  background: #FFFFFF;
  box-shadow: 10px 10px 20px 8px rgba(0,0,0,0.13);
  -webkit-box-shadow: 10px 10px 20px 8px rgba(0,0,0,0.13);
  -moz-box-shadow: 10px 10px 20px 8px rgba(0,0,0,0.13);

  border-radius: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;

  font-family: gibsonregular;
  font-size: 22px;
}

.content {
  position: relative;
  padding: 80px 60px;
}

.contact_info {
  float: left;
  text-align: left;
  width: 50%;
  margin-bottom: 10px;
}

.small_screen .contact_info {
  float: none;
  width: 100%;
}

.label {
  font-family: gibsonsemibold;
  font-size: 16px;
}

.input_container {
  margin: 14px 0 30px 0;
}

.birthdate_info {
  text-align: left;
  margin-left: 54%;
  width: 46%;
}

.small_screen .birthdate_info {
  margin-left: 0;
  width: 100%;
}

.birthdate_info .label {
  margin-bottom: 14px;
}

.tip {
  font-size: 16px;
  color: #35393C;
  margin: 12px 0px;
}

.buttons button {
  float: left;
  width: 200px;
  margin: 16px 20px 0px 0px;
}

.remove {
  color: #C86B6B;
}

.overlay {
  position: absolute;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.81;
  background: #FFFFFF;
}

.highlight {
  background-color: #ffe7b6;
}
</style>
