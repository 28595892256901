<template lang="pug">
div.add_birthday_contacts_container(v-bind:class="{small: isSmallScreen}")
  EditBirthdayContact(
    v-if="contactToEdit"
    v-bind:contact="contactToEdit"
    v-on:closed="contactToEdit=null"
    v-on:updated="refreshReminderList"
  )
  div.contact_list_col(v-bind:class="{full_width: !haveReminders, limit_height: haveReminders}")
    div.view_container
      Header(v-on:is-small-screen="setIsSmallScreen")
      div.view_title Setup Birthday Reminders
      div.description Add people you want to do birthday videos for. We find adding at least 8 people leads to a happier your social life.
      div.input_container
          input(v-model="searchText" type="text" placeholder="Search your contacts or type in an email to add"  ref="searchText")
      div.import_from_google(v-if="noContacts && !searchText && !$apollo.loading")
          button.google_signin(v-on:click="googleSignIn" ) Import your contacts from Google
          div.explanation We will import you email contacts from Gmail. We also send invites from your email to avoid SPAM filters and give you the best chance for them to record a video for you.
      div.loading(v-if="$apollo.loading || loading")
        img(v-bind:src="loadingSrc")
      div.contact_list_container
        div.list(v-bind:class="{border: !noContacts || isSearchValidEmail}")
          div.contact.active.noselect(v-if="isSearchValidEmail" v-on:click="showAddContactModal({email: searchText})")
            div.name.highlight Add using this email
            div.email {{ searchText }}
          div(v-for="(contact,index) in contacts")
            div.contact.active.noselect(v-on:click="showAddContactModal(contact)" :title="contact.email")
              div.name
                span {{truncate(contact.firstName)}}&nbsp;{{ truncate(contact.lastName) }}
                span.added(v-if="contact.added") &nbsp;&nbsp;&nbsp;already added
              div.email {{truncate(contact.email) }}
  div.reminder_list_col(v-if="haveReminders")
    div.reminder_wrapper
      img.calendar_icon(:src="calendarIconSrc")
      div.title Your Birthday Reminders
        div.reminder_list
          div(v-for="(reminder, index) in birthdayReminders")
            div.reminder.clickable(v-on:click="showUpdateContactModal(reminder)")
              div.name {{reminder.firstName}} &nbsp; {{reminder.lastName}}
              div.birthdate(:class="{unknown_date: !reminder.birthdate}") {{ formatBirthdate(reminder.birthdate) }}


</template>

<script>
/* global gtag */
import { GET_CONTACTS_QUERY, GET_BIRTHDAY_REMINDERS_QUERY } from '../graphql/queries'
import { formatDate, isEmail } from '../lib/utils'

import Header from '../components/Header'
import config from '../appConfig'
import EditBirthdayContact from '../components/EditBirthdayContact'

export default {
  name: 'AddBirthdayContacts',
  metaInfo() {
    return {
        title: `Setup Birthday Beminders`,
        meta: [
            { property: 'og:title', content: `Add birthday reminders`},
            {name: 'robots', content: 'index,follow'}
        ]
    }
  },
  props: {
    project_id: {
      type: String,
      default: null
    },
  },
  components: {
    Header,
    EditBirthdayContact,
  },
  watch: {

  },
  computed: {
    noContacts () {
      return (!this.contacts || this.contacts.length <= 0)
    },
    haveReminders () {
      return this.birthdayReminders != null && this.birthdayReminders.length > 0
    },
    loadingSrc () {
      return process.env.BASE_URL + "loading_dark.gif"
    },
    calendarIconSrc () {
      return process.env.BASE_URL + "reminder_calendar_icon.svg"
    },
    isSignedIn () {
      return this.$store.getters.signedIn
    },
    isSearchValidEmail () {
      return isEmail(this.searchText)
    },
  },
  data () {
    return {
      name: null,
      email: null,
      loading: false,
      isSmallScreen: config.isSmallScreen(),
      contacts: null,
      birthdayReminders: null,
      searchText: null,
      contactToEdit:null,
      authCode: null,
    }
  },
  apollo: {
    contacts () {
      return {
        // gql query
        query: GET_CONTACTS_QUERY,
        variables () {
          return {
            searchText: this.searchText,
            authCode: this.authCode,
          }
        },
        update: data => {
          return data.contacts
        },
        skip () {
          return !this.isSignedIn
        },
        error: (error) => {
          console.log(error.message)
        },
        fetchPolicy: 'network-only',
      }
    },
    birthdayReminders () {
      return {
        // gql query
        query: GET_BIRTHDAY_REMINDERS_QUERY,
        update: data => {
          return data.birthdayReminders
        },
        skip () {
          return !this.isSignedIn
        },
        error: (error) => {
          console.log(error.message)
        },
        fetchPolicy: 'network-only',
      }
    }
  },
  methods: {
    googleSignIn: async function () {
      this.authCode = await this.$gAuth.getAuthCode()
    },
    refreshReminderList: function() {
      this.searchText = ""
      this.$apollo.queries.birthdayReminders.refetch()
    },
    formatBirthdate: function(birthdate) {
      if (!birthdate) return "birthday not known"
      return formatDate(birthdate, false)
    },
    truncate: function(text) {
      if (!text) return

      const max_length = this.isSmallScreen ? 28 : 18
      const email_match = text.match(/(.+)(@.+\.(?:.{3}|.{2}))/)
      if (email_match) {
        if (email_match[1] && email_match[1].length >max_length) return email_match[1].slice(0,max_length)+"..."+email_match[2]
        return text
      }

      if (text.length > max_length) {
        text = text.slice(0,max_length) + "..."
      }
      return text
    },
    showAddContactModal: function(contact) {
      this.contactToEdit = contact
    },
    showUpdateContactModal: function(contact) {
      this.contactToEdit = contact
    },
    setIsSmallScreen: function(isSmall) {
      this.isSmallScreen = isSmall
    },
  },
  mounted: function () {
    // window.vue = this
    gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID, {'page_path': '/add_birthday_contacts'})

    if (!this.isSignedIn) {
      this.$toast.error("Please sign in first. Click here to close this and we'll take you to the sign in page.",{
        timeout: null,
        onClose: () => this.$router.push({
          name:"LoginWithNext",
          params: {
            next: "/reminders/setup/"
          }
        })
      })
    }
  }
}
</script>

<style scoped>

.contact_list_col {
  position: fixed;
  z-index: 100;
  background-color: #ffffff;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 70%;
}

.small .contact_list_col {
  position: fixed;
  width: 100%;
}

.small .limit_height {
  height: 70vh;
}

.full_width {
  width: 100%;
}

.view_container {
  display: flex;
  flex-flow: column;
  width: 94% !important;
  height: 94% !important;
}

#header, .view_title, .input_container, .loading {
  flex: 0 1 auto;
}

.contact_list_container {
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
}

.reminder_list_col {
  position: fixed;
  z-index: 100;
  background-color: #4800CD;
  top: 0px;
  left: 70%;
  height: 100%;
  width: 30%;
  overflow-y: auto;
}

.small .reminder_list_col {
  position: fixed;
  width: 100%;
  height: 30vh;
  top: unset;
  left: 0px;
  bottom: 0px;
}

.view_title {
  margin-bottom: 8px;
}

.description {
  font-family: gibsonregular;
  font-size: 20px;
  margin-bottom: 12px;
}

.bold {
  font-weight: bold;
}

.controls {
  margin: 20px 0px;
}

.list {
  border: none;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;

  font-family: gibsonregular;
  font-size: 18px;

  overflow: hidden;
}

.border {
  border: 1px solid #F5F5F6;
}

.label {
  font-size: 18px;
}

.name {
  font-family: gibsonsemibold;
}

.active .name {
  color: #000000;
}

.contact div {
  display: inline-block;
}

.email {
  float: right;
}

.small .contact .email, .small .contact .name {
  display: block;
  float:none;
  text-align: left;
  margin-bottom: 6px;
}

a, .highlight {
  color: #6f63bd !important;
}

.contact {
  padding: 20px 4%;
  border-bottom: 1px solid #F5F5F6;
  background-color: #f8f8f8;
}

.list .active {
  cursor: pointer;
  background-color: #ffffff;
}

.list .active:hover {
  background-color: #f8f8f8;
}

.added {
  font-family: gibsonregular;
}

.reminder_wrapper {
  padding: 20px;
}

.reminder_wrapper .title {
  margin-top: 18px;
  font-family: gibsonsemibold;
  font-size: 20px;
  color: #1C0051;
}

.reminder_list {
  margin-top: 34px;
}

.reminder {
  position:relative;
  background: rgba(20,5,50,0.20);
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  padding: 14px 28px;
  color: #FFFFFF;
  margin: 10px 0;
}

.reminder .name {
  font-size: 18px;
  margin-bottom:4px;
}

.birthdate {
  font-family: gibsonregular;
  font-size: 18px;
}

.unknown_date {
  color: #1C0051;
}

.reminder:hover {
  background: rgba(12, 1, 32, 0.5);
}

.calendar_icon {
  display: block;
  margin-top: 18%;
  height: 32px;
}

.small .reminder_wrapper .calendar_icon {
  margin-top: 4px;
}

.import_from_google {
  margin: 20px 0;
}

.explanation {
  font-family: gibsonregular;
  font-size: 18px;
  margin: 20px 8px;
  color: #B3B3B3;
}

</style>
